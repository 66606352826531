<template>
<div>
    <v-card>
        <v-card-title class="pa-4 info">
            <span class="title white--text">LISTE DES COLLECTES DES DONNEES AU NIVEAU DES PLUVIOMETRES</span>
        </v-card-title>

        <v-card-text>

            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="listdepartement" @change="fuseSearchdepartement" outlined hide-details item-value="id" v-model="selectdepartement" label="Departement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listcommune" @change="fuseSearchcommune" outlined hide-details item-value="id" v-model="selectcommune" label="Commune *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listarrondissement" @change="fuseSearcharrondissement" outlined hide-details item-value="id" v-model="selectarrondissement" label="Arrondissement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select :items="listvillage" outlined hide-details item-value="id" v-model="selectvillage" label="Village/Ville *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
             
                    <v-row>
                    
                    <v-col cols="12" sm="6">
                        <v-select :items="listannee" outlined hide-details  v-model="selectannee" label="Annee *">
                           
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select :items="listtrimestre" outlined hide-details  v-model="selecttrimestre" @change="fuseSearchtrimestre" label="Trimestre *">
                           
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select :items="listmois" outlined hide-details  v-model="selectmois" label="Mois *">
                            
                        </v-select>
                    </v-col>
                    
                    <v-col cols="12" sm="6">
                        <v-select :items="list" outlined hide-details item-value="id" v-model="selectdirection" label="List des directions *">
                            <template slot="selection" slot-scope="data">
                                {{ data.item.libelle_atda }}
                            </template>
                            <template slot="item" slot-scope="data">
                                {{ data.item.libelle_atda }}
                            </template>
                        </v-select>
                    </v-col>

                </v-row>
            </v-col>


                </v-row>

            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" dark @click="dialog=false">Annuler</v-btn>
            <v-btn color="success" dark @click="recherche">Recherche</v-btn>
        </v-card-actions>
    </v-card>


    <gmap-map :center="center" :zoom="8" style="width:100%;  height: 900px;">
        <gmap-marker 
        
        v-for="(m, index) in markers" 
        :key="index" 
        :position="getposition(m)" 
        :clickable="true" 
        :draggable="false" 
        :icon="{ url: require('@/assets/icons8-pluviomètre-48.png')}"
        @click="handleMarkerCliked(m)">
    </gmap-marker>

    <gmap-info-window 
    :position="infoPosition" 
    :options="infoOptions"
    :opened="infoOpened"
    @closeclick="handleInfoWindowClose">
        <div style="color: #000">
            <span style="font-weight: bold">{{activepluviometre.libelle}}</span><br />
            <span>{{activepluviometre.description}}</span><br />
            <span>{{activepluviometre.ville}}</span><br />
            <span>{{activepluviometre.pays}}</span><br />
            <span>{{activepluviometre.code_postal}}</span><br />
            <span>{{activepluviometre.latitude}}</span><br />
            <span>{{activepluviometre.longitude}}</span><br />
        </div>
    
       
    </gmap-info-window>

    </gmap-map>

</div>
</template>

  
<script>
import api from '@/serviceApi/apiService'
export default {
    name: "GoogleMap",
    data() {
        return {
            center: { lat: 9.890257, lng: 2.707417},
            markers: [],
            infoPosition: { lat: 9.890257, lng: 2.707417},
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoOpened:false,
            activepluviometre:{},
            currentPlace: null
        };
    },

    mounted() {
        this.geolocate();
    },

    methods: {
        setPlace(place) {
            this.currentPlace = place;
        },

        geolocate: async function () {

            let fd = new FormData();
            const data = await api.createUpdatedata('admin-backoffice/carte-pluviometre', fd);
            this.markers = await data.markers;
           

        },

        getposition(m){

        return {lat: parseFloat(m.latitude), lng: parseFloat(m.longitude)}
      },
      handleMarkerCliked(m){
        this.activepluviometre = m;
        this.infoPosition = this.infoWindowsPosition();
        this.infoOpened = true;
      },
      handleInfoWindowClose(){
        this.activepluviometre = {};
        this.infoOpened = false;
      },

      infoWindowsPosition(){
        return {lat: parseFloat(this.activepluviometre.latitude), lng: parseFloat(this.activepluviometre.longitude)}
      },
}
};
</script>
